import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductsPromotionBlock from 'libs/wordpress/content/cmsBlocks/ProductsPromotionBlock';
import React from 'react';
import moduleSpacing from 'config/theme/moduleSpacing';

const ProductsPromotionLayout = ({ ...rest }) => (
    <MaxWidthWrapper includeContentSpacing as="section" py={moduleSpacing.md}>
        <ProductsPromotionBlock {...rest} />
    </MaxWidthWrapper>
);

export default ProductsPromotionLayout;
